import React, { createContext, useState, useEffect } from "react";
import { useUser, useSession } from "@clerk/clerk-react";

import axios from "axios";

const DataContext = createContext();

const MyProvider = ({ children }) => {
  const { session } = useSession();
  const [sessionToken, setSessionToken] = useState(null);
  const { user } = useUser();

  useEffect(() => {
    const fetchSessionToken = async () => {
      if (session) {
        const token = await session.getToken({
          template: `Park-Notify-Backend`,
        });
        setSessionToken(token);
        console.log(`SessionToken`, token);
      }
    };

    fetchSessionToken();
  });

  const updateSurvey = async (survey) => {
    console.log(survey);
    if (!session) {
      console.error(`No session available`);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/v1/user/survey`,
        { survey: survey },
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error(`Error updating Survey:`, error);
    }
  };

  const findNearbyParking = async (center) => {
    console.log(center);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/v1/parking/`,
        { center: center },
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error(`Error updating Survey:`, error);
      return [];
    }
  };

  return (
    <DataContext.Provider
      value={{
        user,
        updateSurvey,
        findNearbyParking,
        sessionToken,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export { DataContext, MyProvider };
