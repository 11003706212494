import React from "react";
import SurveyHeader from "./SurveyHeader";

const UserDemographic = ({ nextStep, handleChange, values }) => {
  const continueNext = (e) => {
    e.preventDefault();
    nextStep();
  };

  const handleFieldChange = (e) => {
    handleChange({ [e.target.name]: e.target.value });
  };

  return (
    <form onSubmit={continueNext}>
      {/* <h2 className="text-2xl font-bold mb-6">User Demographic</h2> */}

      <SurveyHeader />

      <div className="mb-4">
        <label htmlFor="age" className="block mb-2">
          Age
        </label>
        <select
          id="age"
          name="age"
          value={values.age || ""}
          onChange={handleFieldChange}
          className="w-full p-2 border rounded"
          required
        >
          <option value="">Select Age</option>
          {[...Array(83)].map((_, i) => (
            <option key={i} value={i + 18}>
              {i + 18}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-4">
        <label className="block mb-2">Gender</label>
        {["Male", "Female", "Other", "Prefer not to say"].map((option) => (
          <label key={option} className="inline-flex items-center mr-4">
            <input
              type="radio"
              name="gender"
              value={option}
              checked={values.gender === option}
              onChange={handleFieldChange}
              className="mr-2"
              required
            />
            {option}
          </label>
        ))}
      </div>

      <div className="mb-4">
        <label htmlFor="location" className="block mb-2">
          Location
        </label>
        <input
          type="text"
          id="location"
          name="location"
          value={values.location || ""}
          onChange={handleFieldChange}
          className="w-full p-2 border rounded"
          placeholder="Enter your city/town"
          required
        />
      </div>

      <div className="mb-4">
        <label htmlFor="occupation" className="block mb-2">
          Occupation
        </label>
        <input
          type="text"
          id="occupation"
          name="occupation"
          value={values.occupation || ""}
          onChange={handleFieldChange}
          className="w-full p-2 border rounded"
          placeholder="Enter your occupation"
          required
        />
      </div>

      <button
        type="submit"
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
      >
        Next
      </button>
    </form>
  );
};

export default UserDemographic;
