import React from "react";
import SurveyHeader from "./SurveyHeader";

const AppFeatures = ({ nextStep, prevStep, handleChange, values }) => {
  const continueNext = (e) => {
    e.preventDefault();
    nextStep();
  };

  const goBack = (e) => {
    e.preventDefault();
    prevStep();
  };

  const handleFieldChange = (e) => {
    if (e.target.type === "checkbox") {
      const updatedFeatures = e.target.checked
        ? [...(values.desiredFeatures || []), e.target.value]
        : (values.desiredFeatures || []).filter(
            (feature) => feature !== e.target.value
          );
      handleChange({ desiredFeatures: updatedFeatures });
    } else {
      handleChange({ [e.target.name]: e.target.value });
    }
  };

  return (
    <form onSubmit={continueNext}>
      {/* <h2 className="text-2xl font-bold mb-6">App Features</h2> */}
      <SurveyHeader />
      <div className="mb-4">
        <label className="block mb-2">
          Would you use a mobile app to pay for parking?
        </label>
        {["Yes", "No", "Maybe"].map((option) => (
          <label key={option} className="inline-flex items-center mr-4">
            <input
              type="radio"
              name="useAppForParking"
              value={option}
              checked={values.useAppForParking === option}
              onChange={handleFieldChange}
              className="mr-2"
              required
            />
            {option}
          </label>
        ))}
      </div>

      <div className="mb-4">
        <label className="block mb-2">
          How important is the ability to pay for parking through an app?
        </label>
        {[
          "Very important",
          "Somewhat important",
          "Neutral",
          "Not important",
          "Not at all important",
        ].map((option) => (
          <label key={option} className="inline-flex items-center mr-4">
            <input
              type="radio"
              name="appImportance"
              value={option}
              checked={values.appImportance === option}
              onChange={handleFieldChange}
              className="mr-2"
              required
            />
            {option}
          </label>
        ))}
      </div>

      <div className="mb-4">
        <label className="block mb-2">
          What features would make you more likely to use a parking payment app?
          (Select all that apply)
        </label>
        {[
          "Ability to find parking spaces",
          "Real-time parking availability",
          "Notifications when parking is about to expire",
          "Integration with local shop promotions/offers",
          "Loyalty rewards or discounts",
          "Easy payment options (e.g., Apple Pay, Google Pay)",
          "Ability to extend parking time remotely",
          "Integration with navigation apps",
        ].map((option) => (
          <label key={option} className="inline-flex items-center mr-4">
            <input
              type="checkbox"
              name="desiredFeatures"
              value={option}
              checked={(values.desiredFeatures || []).includes(option)}
              onChange={handleFieldChange}
              className="mr-2"
            />
            {option}
          </label>
        ))}
      </div>

      <div className="mb-4">
        <label className="block mb-2">
          How interested are you in receiving promotions and offers from nearby
          shops after paying for parking?
        </label>
        {[
          "Very interested",
          "Somewhat interested",
          "Neutral",
          "Not interested",
          "Not at all interested",
        ].map((option) => (
          <label key={option} className="inline-flex items-center mr-4">
            <input
              type="radio"
              name="promotionInterest"
              value={option}
              checked={values.promotionInterest === option}
              onChange={handleFieldChange}
              className="mr-2"
              required
            />
            {option}
          </label>
        ))}
      </div>

      <div className="flex justify-between">
        <button
          type="button"
          onClick={goBack}
          className="bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400"
        >
          Previous
        </button>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Next
        </button>
      </div>
    </form>
  );
};

export default AppFeatures;
