import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useRef,
} from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  ZoomControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import AboutModal from "../component/AboutModal";

import GeocodingSearch from "./GeoCoding";
//import { findNearbyParking } from "../utils/findNearbyParking";
import { DataContext } from "../dataContext/DataContext";

import { SignOutButton, useClerk } from "@clerk/clerk-react";

// Fix the default marker icon issue in Leaflet
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import { ParkingCard } from "../component/ParkingCard";

const DefaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

const userLocation = L.icon({
  iconUrl: "/userLoc.webp",
  iconSize: 20,
});

L.Marker.prototype.options.icon = DefaultIcon;

// Updated ChangeMapView component for smooth transitions
const ChangeMapView = ({ center, zoom, myLocation }) => {
  console.log("Current location", center);
  const map = useMap();
  useEffect(() => {
    if (center?.lat && center?.lng) {
      map.flyTo(center, zoom, {
        duration: 1.5, // Duration of the animation in seconds
        easeLinearity: 0.45, // Easing factor (0-1)
      });
    }
  }, [map, center, zoom, myLocation]);
  return null;
};

// This component will handle markers

const Markers = React.memo(({ myLocation, nearbyParking, center }) => {
  const markerRefs = useRef([]);

  const handleClosePopup = (index) => {
    if (markerRefs.current[index]) {
      markerRefs.current[index].closePopup(); // Access the marker's popup via its method
    }
  };

  console.log("USER LOCATION IN MEMO:", myLocation);

  return (
    <>
      {myLocation && (
        <Marker
          icon={userLocation}
          position={[myLocation?.lat, myLocation?.lng]}
        >
          {/* <Popup>Your Location</Popup> */}
        </Marker>
      )}
      {center && (
        <Marker icon={userLocation} position={[center?.lat, center?.lng]}>
          {/* <Popup>Your Location</Popup> */}
        </Marker>
      )}
      {nearbyParking.map((location, index) => (
        <Marker
          key={`parking-${index}`}
          position={[Number(location.Latitude), Number(location.Longitude)]}
          ref={(el) => {
            if (el && !markerRefs.current[index]) {
              markerRefs.current[index] = el;
            }
          }}
        >
          <Popup>
            <ParkingCard
              location={location}
              onClose={() => handleClosePopup(index)}
            />
          </Popup>
        </Marker>
      ))}
    </>
  );
});

const NavIcon = ({ src, alt, onClick, style, name, className }) => (
  <div className="flex flex-col items-center">
    <button
      className={`p-2 hover:bg-blue-100 rounded-full transition-colors duration-200 focus:outline-none flex items-center justify-center ${
        className ?? ""
      }`}
      onClick={(e) => {
        console.log("NavIcon clicked:", alt);
        onClick(e);
      }}
    >
      <img
        src={src}
        alt={alt}
        className="w-auto h-8 object-contain"
        onError={() => console.log(`Failed to load image: ${src}`)}
        style={style}
      />
    </button>
    <p className={`text-xs font-medium mt-1 text-center ${className ?? ""}`}>
      {name}
    </p>
  </div>
);

const ParkingSpotDetail = ({ spot, onBack, isDesktop }) => {
  const handleNavigate = () => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${spot.Latitude},${spot.Longitude}`;
    window.open(url, "_blank");
  };
  let chargesTime = spot["Charge Times"].split(" ");
  let dates = [chargesTime[0], chargesTime[1], chargesTime[2]];
  let time = [chargesTime[3], chargesTime[4], chargesTime[5]];

  return (
    <div className="p-4 flex flex-col h-full text-[#0475BB]">
      <div
        className="bg-gray-100 rounded-lg text-center h-10 w-8 mt-4 "
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: 10,
          alignSelf: "flex-end",
          width: 30,
          height: 40,
          padding: 11,
        }}
        onClick={onBack}
      >
        {isDesktop && (
          <img
            src={"/backArrow.png"}
            alt={"mdi_cctv"}
            onError={() => console.log(`Failed to load image: `)}
          />
        )}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <h2 className="text-2xl font-bold text-white">
            {spot["Title"].slice(0, 25)}
          </h2>
          <h2 className="mb-4 text-white">
            {spot["Alternate Title"].slice(0, 40)}{" "}
          </h2>
        </div>

        <div
          className="bg-gray-100 rounded-lg text-center h-10 w-10 "
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 15,
            width: 40,
            height: 40,
            padding: 5,
          }}
        >
          <img
            src={"/ulez.png"}
            alt={"mdi_cctv"}
            onError={() => console.log(`Failed to load image: `)}
          />
        </div>
      </div>
      <div className="flex" style={{ margin: 0, marginBottom: 10 }}>
        {/* {Array(5)
          .fill(0)
          .map((_, index) => (
            <img
              src={"/star_filled.png"}
              alt={"mdi_cctv"}
              className="w-8 h-8"
              onError={() => console.log(`Failed to load image: `)}
            />
          ))} */}
      </div>

      <div className="space-y-4 flex-grow">
        <div className="bg-gray-100 p-4 rounded-lg text-center">
          <h3 className="font-normal text-sm mb-2">Working Hours</h3>
          <p className="font-bold text-lg">
            {dates.join(" ") || "Not specified"}
          </p>
          <p className="font-bold text-lg">
            {time.join(" ") || "Not specified"}
          </p>
        </div>

        <div className="flex space-x-4">
          <div className="bg-gray-100 p-4 rounded-lg flex-1 text-center">
            <h3 className="font-normal text-sm mb-2">Max Stay</h3>
            <p className="font-bold text-lg">
              {spot["Maximum Stay"] || "Not specified"}
            </p>
          </div>
          <div className="bg-gray-100 p-4 rounded-lg flex-1 text-center">
            <h3 className="font-normal text-sm mb-2">No Return Within</h3>
            <p className="font-bold text-lg">
              {spot.NoReturnWithin || "Not specified"}
            </p>
          </div>
        </div>
        {/* <div className="flex justify-center w-full flex-wrap bg-red gap-6">
          <img
            src={"/mdi_car-stop-coveredw.png"}
            alt={"cars"}
            className="w-8 h-8"
            onError={() => console.log(`Failed to load image: `)}
          />

          <img
            src={"/mdi_post-lightw.png"}
            alt={"cars"}
            className="w-8 h-8"
            onError={() => console.log(`Failed to load image: `)}
          />
          <img
            src={"/mdi_cctvw.png"}
            alt={"mdi_cctv"}
            className="w-8 h-8"
            onError={() => console.log(`Failed to load image: `)}
          />
          <img
            src={"/mingcute_disabled-fillw.png"}
            alt={"mdi_cctv"}
            className="w-8 h-8"
            onError={() => console.log(`Failed to load image: `)}
          />
          <img
            src={"/mdi_securew.png"}
            alt={"mdi_cctv"}
            className="w-8 h-8"
            onError={() => console.log(`Failed to load image: `)}
          />
          <img
            src={"/mdi_electric-vehicle-chargerw.png"}
            alt={"mdi_cctv"}
            className="w-8 h-8"
            onError={() => console.log(`Failed to load image: `)}
          />
          <img
            src={"/mdi_airport-shuttlew.png"}
            alt={"mdi_cctv"}
            className="w-8 h-8"
            onError={() => console.log(`Failed to load image: `)}
          />
        </div> */}
      </div>
      <button
        onClick={handleNavigate}
        className="mt-4 bg-white text-[#0475BB] px-4 py-2 rounded-lg flex items-center justify-center hover:opacity-80 w-full"
        style={{ gap: 10 }}
      >
        Navigate
        <img
          src={"/carw.png"}
          alt={"mdi_cctv"}
          className="w-4 h-4"
          onError={() => console.log(`Failed to load image: `)}
        />
      </button>
      <button
        // onClick={toggleSidebar}
        className="mt-4 bg-[#0475BB] text-white px-4 py-2 rounded-lg flex items-center justify-center hover:opacity-80 w-full"
      >
        Close
      </button>
    </div>
  );
};

const MyMapComponent = () => {
  const [myLocation, setMyLocation] = useState(null);
  const [nearbyParking, setNearbyParking] = useState([]);
  const [center, setCenter] = useState({ lat: 51.461, lng: -0.217 });
  const [zoom, setZoom] = useState(14);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedSpot, setSelectedSpot] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebarOrModal = useCallback(() => {
    if (isDesktop) {
      setSidebarOpen((prevState) => !prevState);
    } else {
      setIsModalOpen(true);
    }
  }, [isDesktop]);

  const { findNearbyParking, sessionToken } = useContext(DataContext);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const myLoc = {
          lat: Number(position.coords.latitude),
          lng: Number(position.coords.longitude),
        };
        console.log("My location", myLoc);
        setMyLocation(myLoc);
        setCenter(myLoc);
      },
      (error) => console.log("Geolocation error:", error)
    );
  }, []);

  useEffect(() => {
    if (sessionToken !== null) {
      const fetchNearbyParking = async () => {
        try {
          const nearby = await findNearbyParking(center);
          setNearbyParking(nearby);
        } catch (error) {
          console.error("Error fetching nearby parking:", error);
        }
      };

      fetchNearbyParking();
    }
  }, [center, sessionToken]);

  useEffect(() => {
    console.log("Sidebar state changed to:", sidebarOpen);
  }, [sidebarOpen]);

  useEffect(() => {
    // Add custom CSS for zoom controls
    const style = document.createElement("style");
    style.textContent = `
      .leaflet-control-zoom {
        border: none !important;
        border-radius: 8px !important;
        overflow: hidden;
      }
      .leaflet-control-zoom-in,
      .leaflet-control-zoom-out {
        background-color: #0475BB !important;
        color: white !important;
      }
      .leaflet-control-zoom-in:hover,
      .leaflet-control-zoom-out:hover {
        opacity: 0.8;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const handleCenterChange = useCallback((newCenter) => {
    setCenter(newCenter);
    setZoom(16); // Reset zoom when changing center
  }, []);

  const toggleSidebar = useCallback(() => {
    setSidebarOpen((prevState) => !prevState);
    if (selectedSpot) {
      setSelectedSpot(null);
      // Reset center to user's location and zoom when closing detail view
      if (myLocation) {
        setCenter(myLocation);
        setZoom(14);
      }
    }
  }, [selectedSpot, myLocation]);

  const handleSpotClick = useCallback((spot) => {
    setSelectedSpot(spot);
    // Recenter the map to the selected spot's location and set zoom to 18
    setCenter({ lat: Number(spot.Latitude), lng: Number(spot.Longitude) });
    setZoom(18);
  }, []);

  const handleBackToList = useCallback(() => {
    setSelectedSpot(null);
    // Reset center to user's location and zoom when going back to list
    if (myLocation) {
      setCenter(myLocation);
      setZoom(14);
    }
  }, [myLocation]);

  const { openUserProfile } = useClerk();

  const handleCurrentLocationClick = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const myLoc = {
          lat: Number(position.coords.latitude),
          lng: Number(position.coords.longitude),
        };
        console.log("My location", myLoc);
        setMyLocation(myLoc);
        setCenter(myLoc);
        setZoom(14);
      },
      (error) => {
        console.error("Geolocation error:", error);
        // Handle the error appropriately, e.g., show a message to the user
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
    );
  }, []);

  return (
    <div className="flex h-screen text-[#0475BB]">
      {isModalOpen === true && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <AboutModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        </div>
      )}
      <nav className="bg-white shadow-md flex flex-row items-center justify-center space-x-16 z-30 fixed bottom-0 w-[90%] h-auto mx-4 mb-4 rounded-lg md:flex-col md:space-y-8 md:space-x-0 md:justify-start md:w-16 md:h-full md:left-0 md:top-0 md:bottom-auto md:mx-0 md:mb-0 md:rounded-none">
        {/* <NavIcon
          src="/Home.png"
          alt="Logo"
          name={"Home"}
          onClick={() => {}}
          className={"md:hidden flex "}
        /> */}
        {isDesktop && (
          <NavIcon
            src="/logo.png"
            alt="Logo"
            onClick={() => {
              // isDesktop ? toggleSidebar() : setIsModalOpen(true);
            }}
            //name={isDesktop ? "Search" : "About"}
          />
        )}
        <NavIcon
          src={isDesktop ? "/Search.png" : "grid.png"}
          alt="Logo"
          onClick={() => {
            isDesktop ? toggleSidebar() : setIsModalOpen(true);
          }}
          name={isDesktop ? "Search" : "About"}
        />

        <NavIcon
          src="/user1.png"
          alt="About Us"
          onClick={() => {
            openUserProfile();
          }}
          name={"Account"}
        />

        {isDesktop && (
          <NavIcon
            src="/grid.png"
            alt="About"
            onClick={() => {
              setIsModalOpen(true);
            }}
            name={"About"}
          />
        )}

        <SignOutButton>
          <NavIcon
            src="/logout1.png"
            alt="Logout"
            onClick={() => console.log("Logout clicked")}
            name={"Log Out"}
          />
        </SignOutButton>
      </nav>

      {!sidebarOpen && (
        <div className="md:hidden block fixed top-5 z-30 flex items-center justify-center w-full bg-red ">
          <NavIcon src="/logo.png" alt="Logo" onClick={toggleSidebar} />
          <GeocodingSearch
            setCenter={handleCenterChange}
            onMap={true}
            onClick={toggleSidebar}
            isDesktop={isDesktop}
          />
        </div>
      )}
      {/* Main Content */}
      <main className="flex-grow relative">
        {/* Map Section */}
        <div className="absolute inset-0 bg-black z-0">
          <MapContainer
            style={{ height: "100%", width: "100%" }}
            center={center}
            zoom={zoom}
            scrollWheelZoom={false}
            zoomControl={false}
          >
            <TileLayer
              url={`https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}&key=${process.env.REACT_APP_GOOGLE_KEY}`}
              attribution='&copy; <a href="https://maps.google.com">Google Maps</a>'
            />
            <ChangeMapView
              center={center}
              zoom={zoom}
              myLocation={myLocation}
            />
            <Markers
              myLocation={myLocation}
              nearbyParking={nearbyParking}
              center={center}
            />

            {/* Custom control for current location button */}

            <div
              style={{
                position: "absolute",
                bottom: 180,
                right: 10,
                zIndex: 10000,
              }}
            >
              <button
                onClick={handleCurrentLocationClick}
                style={{
                  backgroundColor: "#0475BB",
                  border: "none",
                  borderRadius: "50%",
                  width: "29px",
                  height: "29px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src="/Crosshair.png"
                  alt="crossHair"
                  className="w-5 h-5"
                  onError={() => console.log(`Failed to load image:`)}
                />
              </button>
            </div>

            <ZoomControl position="bottomright" />
          </MapContainer>
        </div>

        {/* Overlay Sidebar */}
        <aside
          className={`
            absolute top-0 left-0 h-full bg-[#1B1F26B8] bg-opacity-50 backdrop-blur-md
            transition-all duration-300 ease-in-out overflow-y-auto h-[91%] md:h-[100%]
            ${
              sidebarOpen
                ? "w-[calc(100%-4rem)] md:w-[calc(33.333%-4rem)]"
                : "w-0"
            }
            z-10
            md:ml-16
          `}
        >
          {sidebarOpen && (
            <div className="p-4 h-full flex flex-col">
              <button
                onClick={toggleSidebar}
                className="absolute top-2 right-2 p-2 rounded-full hover:bg-gray-200 transition-colors duration-200"
              >
                {/* <X size={24} color="#0475BB" /> */}
              </button>
              {selectedSpot ? (
                <ParkingSpotDetail
                  spot={selectedSpot}
                  onBack={handleBackToList}
                  isDesktop={isDesktop}
                />
              ) : (
                <>
                  <div className="mt-8">
                    <GeocodingSearch
                      setCenter={handleCenterChange}
                      onClick={toggleSidebar}
                      isDesktop={isDesktop}
                    />
                  </div>
                  <div className="mt-4 flex-grow overflow-y-auto">
                    <h2 className="text-xl font-semibold mb-2 text-white">
                      Nearby Parking Spots
                    </h2>
                    <ul className="space-y-2">
                      {nearbyParking.map((spot, index) => (
                        <li
                          key={index}
                          className="bg-white bg-opacity-75 p-2 rounded shadow cursor-pointer hover:bg-gray-100"
                          onClick={() => handleSpotClick(spot)}
                        >
                          <h3 className="font-medium">{spot.Title}</h3>
                          <p className="text-sm opacity-80">{spot.Address}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </div>
          )}
        </aside>
      </main>
    </div>
  );
};

export default MyMapComponent;
