import { SignedIn, SignedOut } from "@clerk/clerk-react";
import { BrowserRouter as Router } from "react-router-dom";
import SignedOutRoutes from "./routers/signedOut";
import SignedInRoutes from "./routers/signedIn";

import { MyProvider } from "./dataContext/DataContext";
import GoogleMapsProvider from "./component/GoogleMapsProvider";

function App() {
  console.log("Park Notify");

  return (
    <>
      <GoogleMapsProvider>
        <Router>
          <SignedIn>
            <MyProvider>
              <SignedInRoutes />
            </MyProvider>
          </SignedIn>
          <SignedOut>
            <SignedOutRoutes />
          </SignedOut>
        </Router>
      </GoogleMapsProvider>
    </>
  );
}

export default App;
