import React from "react";
import SurveyHeader from "./SurveyHeader";

const ParkingHabits = ({ nextStep, prevStep, handleChange, values }) => {
  const continueNext = (e) => {
    e.preventDefault();
    nextStep();
  };

  const goBack = (e) => {
    e.preventDefault();
    prevStep();
  };

  const handleFieldChange = (e) => {
    handleChange({ [e.target.name]: e.target.value });
  };

  return (
    <form onSubmit={continueNext}>
      {/* <h2 className="text-2xl font-bold mb-6">Parking Habits</h2> */}
      <SurveyHeader />
      <div className="mb-4">
        <label className="block mb-2">
          How often do you park in paid parking areas?
        </label>
        {[
          "Daily",
          "Several times a week",
          "Weekly",
          "A few times a month",
          "Rarely",
        ].map((option) => (
          <label key={option} className="inline-flex items-center mr-4">
            <input
              type="radio"
              name="parkingFrequency"
              value={option}
              checked={values.parkingFrequency === option}
              onChange={handleFieldChange}
              className="mr-2"
              required
            />
            {option}
          </label>
        ))}
      </div>

      <div className="mb-4">
        <label className="block mb-2">
          What type of parking do you use most frequently?
        </label>
        {[
          "On-street parking",
          "Multi-story car parks",
          "Public parking lots",
          "Private parking (businesses, events)",
        ].map((option) => (
          <label key={option} className="inline-flex items-center mr-4">
            <input
              type="radio"
              name="parkingType"
              value={option}
              checked={values.parkingType === option}
              onChange={handleFieldChange}
              className="mr-2"
              required
            />
            {option}
          </label>
        ))}
      </div>

      <div className="mb-4">
        <label className="block mb-2">
          How do you usually pay for parking?
        </label>
        {[
          "Cash",
          "Card (debit/credit)",
          "Apple or Android Pay",
          "Contactless",
          "Other",
        ].map((option) => (
          <label key={option} className="inline-flex items-center mr-4">
            <input
              type="radio"
              name="paymentMethod"
              value={option}
              checked={values.paymentMethod === option}
              onChange={handleFieldChange}
              className="mr-2"
              required
            />
            {option}
          </label>
        ))}
        {values.paymentMethod === "Other" && (
          <input
            type="text"
            name="otherPaymentMethod"
            value={values.otherPaymentMethod || ""}
            onChange={handleFieldChange}
            className="mt-2 w-full p-2 border rounded"
            placeholder="Please specify"
            required
          />
        )}
      </div>

      <div className="flex justify-between">
        <button
          type="button"
          onClick={goBack}
          className="bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400"
        >
          Previous
        </button>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Next
        </button>
      </div>
    </form>
  );
};

export default ParkingHabits;
