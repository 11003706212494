import React from "react";
import SurveyHeader from "./SurveyHeader";

const PromotionsOffers = ({ nextStep, prevStep, handleChange, values }) => {
  const continueNext = (e) => {
    e.preventDefault();
    nextStep();
  };

  const goBack = (e) => {
    e.preventDefault();
    prevStep();
  };

  const handleFieldChange = (e) => {
    if (e.target.type === "checkbox") {
      const updatedPromotions = e.target.checked
        ? [...(values.interestedPromotions || []), e.target.value]
        : (values.interestedPromotions || []).filter(
            (promo) => promo !== e.target.value
          );
      handleChange({ interestedPromotions: updatedPromotions });
    } else {
      handleChange({ [e.target.name]: e.target.value });
    }
  };

  return (
    <form onSubmit={continueNext}>
      {/* <h2 className="text-2xl font-bold mb-6">Promotions and Offers</h2> */}
      <SurveyHeader />

      <div className="mb-4">
        <label className="block mb-2">
          What types of promotions/offers would you be most interested in?
          (Select all that apply)
        </label>
        {[
          "Discounts on food and drinks",
          "Retail shopping offers",
          "Service discounts (e.g., car wash, repair)",
          "Entertainment deals (e.g., cinema tickets)",
          "Health and beauty offers (e.g., gym, spa)",
          "Other",
        ].map((option) => (
          <label key={option} className="inline-flex items-center mr-4">
            <input
              type="checkbox"
              name="interestedPromotions"
              value={option}
              checked={(values.interestedPromotions || []).includes(option)}
              onChange={handleFieldChange}
              className="mr-2"
            />
            {option}
          </label>
        ))}
        {(values.interestedPromotions || []).includes("Other") && (
          <input
            type="text"
            name="otherPromotion"
            value={values.otherPromotion || ""}
            onChange={handleFieldChange}
            className="mt-2 w-full p-2 border rounded"
            placeholder="Please specify"
            required
          />
        )}
      </div>

      <div className="mb-4">
        <label className="block mb-2">
          Would you be more likely to shop at a local business if you received
          an offer after paying for parking?
        </label>
        {["Yes", "No", "Maybe"].map((option) => (
          <label key={option} className="inline-flex items-center mr-4">
            <input
              type="radio"
              name="morelikelyToShop"
              value={option}
              checked={values.morelikelyToShop === option}
              onChange={handleFieldChange}
              className="mr-2"
              required
            />
            {option}
          </label>
        ))}
      </div>

      <div className="mb-4">
        <label className="block mb-2">
          How do you prefer to receive promotions/offers?
        </label>
        {[
          "In-app notifications",
          "SMS",
          "Email",
          "Push notifications",
          "Other",
        ].map((option) => (
          <label key={option} className="inline-flex items-center mr-4">
            <input
              type="radio"
              name="preferredPromotionMethod"
              value={option}
              checked={values.preferredPromotionMethod === option}
              onChange={handleFieldChange}
              className="mr-2"
              required
            />
            {option}
          </label>
        ))}
        {values.preferredPromotionMethod === "Other" && (
          <input
            type="text"
            name="otherPromotionMethod"
            value={values.otherPromotionMethod || ""}
            onChange={handleFieldChange}
            className="mt-2 w-full p-2 border rounded"
            placeholder="Please specify"
            required
          />
        )}
      </div>

      <div className="flex justify-between">
        <button
          type="button"
          onClick={goBack}
          className="bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400"
        >
          Previous
        </button>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Next
        </button>
      </div>
    </form>
  );
};

export default PromotionsOffers;
