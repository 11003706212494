import { SignUp, useUser } from "@clerk/clerk-react";
import { useRef, useEffect } from "react";

const SignUpPage = () => {
  //onst router = useRouter();
  const { user } = useUser();
  console.log(user);
  //const isMobile = router.query.mobile === "true";

  const signUpRef = useRef(null);

  useEffect(() => {
    if (signUpRef.current) {
      signUpRef.current.classList.remove("cl-footerAction");
    }
  }, []);

  return (
    <>
      <div className="flex h-screen min-h-full flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <SignUp signInUrl="/sign-in" />
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="/dashboard.png"
          />
        </div>
      </div>
    </>
  );
};

export default SignUpPage;
