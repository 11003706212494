export const ParkingCard = ({ location, onClose }) => {
  const handleNavigate = () => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${location.Latitude},${location.Longitude}`;
    window.open(url, "_blank");
  };
  let chargesTime = location["Charge Times"]?.split(" ");
  let dates = [];
  let time = [];
  if (chargesTime?.length > 5) {
    dates = [chargesTime[0], chargesTime[1], chargesTime[2]];
    time = [chargesTime[3], chargesTime[4], chargesTime[5]];
  }

  return (
    <div
      style={{
        width: "100%", // Make width responsive
        maxWidth: "350px", // Restrict max width for larger screens
        margin: "0 auto", // Center on smaller screens
      }}
    >
      <div
        className="text-center"
        style={{
          backgroundColor: "#0475BB",
          borderRadius: "12px",
          padding: "10px",
          width: "100%", // Responsive width
          maxWidth: "300px", // Restrict to 300px for larger screens
          margin: "0 auto", // Center on the card
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <h2 className="text-xl font-bold text-white" style={{ margin: 0 }}>
            {location["Title"]?.slice(0, 25)}
          </h2>
          <p className="text-white" style={{ margin: 0 }}>
            {location["Alternate Title"]?.slice(0, 40)}
          </p>
          <div
            className="flex justify-center"
            style={{ margin: 0, marginBottom: 5 }}
          >
            {/* {Array(5)
              .fill(0)
              .map((_, index) => (
                <img
                  src={"/star_filled.png"}
                  alt={"star_filled"}
                  className="w-8 h-8"
                  onError={() => console.log(`Failed to load image: `)}
                />
              ))} */}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <p className="text-white" style={{ margin: 0, fontWeight: "590" }}>
            {dates?.join(" ") || "Mon - Sat"}
          </p>
          <p className="text-white" style={{ margin: 0, fontWeight: "590" }}>
            {time?.join(" ") || "9 am - 4.30 pm"}
          </p>
        </div>
      </div>

      <div className="text-center" style={{ width: "100%", margin: "0 auto" }}>
        <p
          className="text-gray-500 mb-2"
          style={{
            fontWeight: 400,
            fontSize: 17,
            margin: 0,
            marginTop: 5,
            marginBottom: 5,
          }}
        >
          {/* Featured with */}
        </p>
        {/* <div className="flex justify-center w-full flex-wrap gap-1">
          <img
            src={"/mdi_car-stop-covered.png"}
            alt={"cars"}
            className="w-8 h-8"
            onError={() => console.log(`Failed to load image: `)}
          />
          <img
            src={"/mdi_post-light.png"}
            alt={"cars"}
            className="w-8 h-8"
            onError={() => console.log(`Failed to load image: `)}
          />
          <img
            src={"/mdi_cctv.png"}
            alt={"mdi_cctv"}
            className="w-8 h-8"
            onError={() => console.log(`Failed to load image: `)}
          />
          <img
            src={"/mingcute_disabled-fill.png"}
            alt={"mdi_cctv"}
            className="w-8 h-8"
            onError={() => console.log(`Failed to load image: `)}
          />
          <img
            src={"/mdi_secure.png"}
            alt={"mdi_cctv"}
            className="w-8 h-8"
            onError={() => console.log(`Failed to load image: `)}
          />
          <img
            src={"/mdi_electric-vehicle-charger.png"}
            alt={"mdi_cctv"}
            className="w-8 h-8"
            onError={() => console.log(`Failed to load image: `)}
          />
          <img
            src={"/mdi_airport-shuttle.png"}
            alt={"mdi_cctv"}
            className="w-8 h-8"
            onError={() => console.log(`Failed to load image: `)}
          />
        </div> */}
      </div>

      <div
        className="flex justify-around text-center"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%", // Responsive width
          maxWidth: "300px", // Restrict to 300px
          margin: "0 auto", // Center
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "130px",
            justifyContent: "space-between",
          }}
        >
          <p className="text-gray-600">Max Stay</p>
          <p className="text-black font-bold">
            {location["Maximum Stay"] || "1 Hour"}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "130px",
            justifyContent: "space-between",
          }}
        >
          <p className="text-gray-600">No Return</p>
          <p className="text-black font-bold">1 Hour</p>
        </div>
      </div>

      <button
        className="w-full py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200 focus:outline-none mb-1"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: 5,
          maxWidth: "300px", // Restrict to 300px
          backgroundColor: "#0475BB",
          width: "100%",
          margin: "0 auto",
        }}
        onClick={handleNavigate}
      >
        <p style={{ margin: 0, fontWeight: 400, fontSize: 17 }}> Navigate </p>

        <img
          src={"/car.png"}
          alt={"mdi_cctv"}
          className="w-4 h-4"
          onError={() => console.log(`Failed to load image: `)}
        />
      </button>

      <button
        className="w-full py-2 text-gray-600 rounded-lg hover:bg-gray-100 transition-colors duration-200 focus:outline-none"
        style={{ width: "100%", margin: "0 auto" }} // Responsive width
        onClick={() => {
          onClose();
          console.log("location.....", location);
        }}
      >
        <p
          style={{ margin: 0, fontWeight: 400, fontSize: 17, color: "#0475BB" }}
        >
          Close
        </p>
      </button>
    </div>
  );
};
