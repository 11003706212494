import React, { useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";

import SurveyForm from "../pages/SurveyForm";
import MyMapComponent from "../pages/MyMapComponent";
import TutorialScreen from "../pages/Tutorial";

const SignedInRoutes = () => {
  const { user } = useUser();
  const navigation = useNavigate();

  // const clerk = useClerk();

  console.log(user.publicMetadata.profession);

  useEffect(() => {
    if (
      user.publicMetadata.survey === null ||
      user.publicMetadata.survey === undefined
    ) {
      navigation("/survey");
    } else {
      navigation("/");
    }

    // "homepage": "/auth",
  }, []);

  return (
    <Routes>
      <Route path="/tutorial" element={<TutorialScreen />} />
      <Route path="/survey" element={<SurveyForm />} />
      <Route path="/" element={<MyMapComponent />} />
    </Routes>
  );
};

export default SignedInRoutes;
