import React from "react";
import { LoadScript } from "@react-google-maps/api";

const GoogleMapsProvider = ({ children }) => {
  const API_KEY = process.env.REACT_APP_GOOGLE_KEY;

  return (
    <LoadScript googleMapsApiKey={API_KEY} libraries={["places"]}>
      {children}
    </LoadScript>
  );
};

export default GoogleMapsProvider;
