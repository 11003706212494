import { Search, X } from "lucide-react";
import React, { useState, useRef } from "react";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import GoogleMapsProvider from "../component/GoogleMapsProvider";
import { ParkingCard } from "../component/ParkingCard";

const GeocodingSearch = ({ setCenter, onMap, onClick, isDesktop }) => {
  const [query, setQuery] = useState("");
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const autocompleteRef = useRef(null);

  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place && place.geometry) {
      const location = place.geometry.location;
      console.log("......test....", place);

      setQuery(place?.formatted_address);
      handleSearch();
    }
  };

  const API_KEY = process.env.REACT_APP_GOOGLE_KEY;

  const handleSearch = async () => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          query
        )}&key=${API_KEY}`
      );
      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        const { formatted_address, geometry } = data.results[0];
        console.log(" data.results[0].....", data.results[0]);

        const newResult = {
          name: formatted_address,
          lat: geometry.location.lat,
          lng: geometry.location.lng,
        };
        setResult(newResult);
        setCenter({ lat: newResult.lat, lng: newResult.lng });
        setError(null);
      } else {
        setError("No results found");
        setResult(null);
      }
    } catch (err) {
      setError("An error occurred while fetching data");
      setResult(null);
    }
  };

  const handleClear = () => {
    setQuery("");
    setResult(null);
    setError(null);
  };

  return (
    <div className={!onMap ? "space-y-4" : "w-[80%]"}>
      {!onMap && (
        <div className="w-full flex items-end justify-end">
          {/* <div
            className="bg-gray-100 rounded-lg text-center h-10 w-8 mt-4 flex items-center justify-center mb-2.5 self-start"
            style={{
              width: 30,
              height: 40,
              padding: 11,
            }}
            onClick={onClick}
          >
            <img
              src="/backArrow.png"
              alt="Back arrow"
              onError={() => console.log("Failed to load image")}
            />
          </div> */}
        </div>
      )}
      <div className="flex w-full items-center relative">
        <Autocomplete
          onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
          onPlaceChanged={handlePlaceChanged}
          className="w-full"
        >
          <input
            type="text"
            placeholder="Enter a location"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            className="flex-grow p-2 pr-8 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
          />
        </Autocomplete>
        {query && !isDesktop && (
          <button
            onClick={handleClear}
            className="absolute right-12 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <X size={20} />
          </button>
        )}
        {query && isDesktop && (
          <button
            onClick={handleClear}
            className="absolute right-24 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <X size={20} />
          </button>
        )}
        <button
          onClick={handleSearch}
          className="bg-blue-500 text-white p-2 rounded-r-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <Search size={25} />
        </button>

        {isDesktop && (
          <div className="ml-4 flex items-end justify-end">
            <div
              className="bg-gray-100 rounded-lg text-center h-10 w-8  flex items-center justify-center  self-start"
              style={{
                width: 30,
                height: 40,
                padding: 11,
              }}
              onClick={onClick}
            >
              <img
                src="/backArrow.png"
                alt="Back arrow"
                onError={() => console.log("Failed to load image")}
              />
            </div>
          </div>
        )}
      </div>

      {result && !onMap && (
        <div className="bg-white p-4 rounded-md shadow flex justify-center">
          <ParkingCard
            location={{
              Title: result?.name,
              "Alternate Title": result?.name,
              Latitude: result.lng,
              Longitude: result.lat,
            }}
            onClose={() => {
              setResult(null);
            }}
          />
        </div>
      )}

      {error && !onMap && (
        <div
          className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4"
          role="alert"
        >
          <p className="font-bold">Error</p>
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export default GeocodingSearch;
