import React from "react";
import { X } from "lucide-react";
import ModalHeader from "./ModalHeader";

const AboutModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white w-4/5 h-4/5 rounded-lg shadow-lg relative flex flex-col">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <X size={24} />
        </button>
        <div className="p-6 flex-grow overflow-auto">
          <div className="flex justify-center mb-4">
            <ModalHeader />
          </div>
          <div className="text-left">
            <p className="mb-2">
              Enjoy free access to ParkingNearby.co.uk, an app made by the Park
              Notify team. Creating a seamless parking experience.
            </p>
            <p className="mb-2">
              We are in the process of creating a digital app where we put the
              driver first, making parking easily accessible, simplifying
              parking payments and going beyond parking.
            </p>
            <p className="mb-2">
              We would like you to try out this app and give us feedback, tell
              us what made your experience better and what would help you in the
              future.
            </p>
            <p className="mb-2">
              Disclaimer: At the moment, the app has limited parking data, the
              next version will be more advanced.
            </p>
            <p className="mb-2">
              Please send all your feedback to{" "}
              <span className="font-bold">
                <a href="mailto:info@park-notify.com">info@park-notify.com</a>
              </span>
            </p>
            <p className="mb-2">
              To stay updated, follow us on LinkedIn at Park Notify.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutModal;
