import React from "react";

const NavIcon = ({ src, alt, onClick, style, name, className }) => (
  <div className="flex flex-col">
    <button
      className={
        "p-auto hover:bg-blue-100 rounded-full transition-colors duration-200 focus:outline-none " +
        (className ?? "")
      }
      onClick={(e) => {
        console.log("NavIcon clicked:", alt);
        onClick(e);
      }}
    >
      <img
        src={src}
        alt={alt}
        className="w-12 h-auto object-contain "
        onError={() => console.log(`Failed to load image: ${src}`)}
        style={style}
      />
    </button>
  </div>
);

const ModalHeader = () => {
  return (
    <div className="flex items-center mb-4 p-4 bg-white rounded-lg shadow-sm">
      <div className="flex items-center mr-2">
        <NavIcon src="/logo.png" alt="Logo" />
      </div>
      <div className="text-left">
        <h1 className="text-xl font-bold text-[#0475BB]">
          The Park Notify Project
        </h1>
      </div>
    </div>
  );
};

export default ModalHeader;
