import React from "react";
import SurveyHeader from "./SurveyHeader";

const UserExperience = ({ nextStep, prevStep, handleChange, values }) => {
  const continueNext = (e) => {
    e.preventDefault();
    nextStep();
  };

  const goBack = (e) => {
    e.preventDefault();
    prevStep();
  };

  const handleFieldChange = (e) => {
    if (e.target.type === "checkbox") {
      const updatedChallenges = e.target.checked
        ? [...(values.parkingChallenges || []), e.target.value]
        : (values.parkingChallenges || []).filter(
            (challenge) => challenge !== e.target.value
          );
      handleChange({ parkingChallenges: updatedChallenges });
    } else {
      handleChange({ [e.target.name]: e.target.value });
    }
  };

  return (
    <form onSubmit={continueNext}>
      {/* <h2 className="text-2xl font-bold mb-6">User Experience</h2> */}
      <SurveyHeader />

      <div className="mb-4">
        <label className="block mb-2">
          What challenges do you currently face with parking payment methods?
          (Select all that apply)
        </label>
        {[
          "Difficulty finding available parking",
          "Inconvenient payment options",
          "Expired parking tickets",
          "Lack of change for cash payments",
          "No issues",
          "Other",
        ].map((option) => (
          <label key={option} className="inline-flex items-center mr-4">
            <input
              type="checkbox"
              name="parkingChallenges"
              value={option}
              checked={(values.parkingChallenges || []).includes(option)}
              onChange={handleFieldChange}
              className="mr-2"
            />
            {option}
          </label>
        ))}
        {(values.parkingChallenges || []).includes("Other") && (
          <input
            type="text"
            name="otherChallenge"
            value={values.otherChallenge || ""}
            onChange={handleFieldChange}
            className="mt-2 w-full p-2 border rounded"
            placeholder="Please specify"
            required
          />
        )}
      </div>

      <div className="mb-4">
        <label className="block mb-2">
          On a scale of 1-5, how likely are you to recommend an app that
          combines parking payment with local shop promotions to friends and
          family?
        </label>
        {[1, 2, 3, 4, 5].map((option) => (
          <label key={option} className="inline-flex items-center mr-4">
            <input
              type="radio"
              name="recommendationLikelihood"
              value={option}
              checked={values.recommendationLikelihood === option.toString()}
              onChange={handleFieldChange}
              className="mr-2"
              required
            />
            {option}{" "}
            {option === 1
              ? "(Not at all likely)"
              : option === 5
              ? "(Very likely)"
              : ""}
          </label>
        ))}
      </div>

      <div className="mb-4">
        <label className="block mb-2">
          Would you be willing to pay a small convenience fee for the additional
          features of the app (e.g., real-time parking info, promotions)?
        </label>
        {["Yes", "No", "Maybe"].map((option) => (
          <label key={option} className="inline-flex items-center mr-4">
            <input
              type="radio"
              name="willingToPayFee"
              value={option}
              checked={values.willingToPayFee === option}
              onChange={handleFieldChange}
              className="mr-2"
              required
            />
            {option}
          </label>
        ))}
      </div>

      <div className="flex justify-between">
        <button
          type="button"
          onClick={goBack}
          className="bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400"
        >
          Previous
        </button>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Next
        </button>
      </div>
    </form>
  );
};

export default UserExperience;
