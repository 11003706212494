import React from "react";
import SurveyHeader from "./SurveyHeader";

const Suggestions = ({ prevStep, handleChange, handleSubmit, values }) => {
  const submitForm = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  const goBack = (e) => {
    e.preventDefault();
    prevStep();
  };

  const handleFieldChange = (e) => {
    handleChange({ [e.target.name]: e.target.value });
  };

  return (
    <form onSubmit={submitForm}>
      {/* <h2 className="text-2xl font-bold mb-6">Your Ideas on Park Notify!</h2> */}
      <SurveyHeader />

      <div className="mb-4">
        <label htmlFor="appSuggestion" className="block mb-2">
          We're open to suggestions, what feature would you like to see on a
          parking app?
        </label>
        <textarea
          id="appSuggestion"
          name="appSuggestion"
          value={values.appSuggestion || ""}
          onChange={handleFieldChange}
          className="w-full p-2 border rounded"
          rows="4"
          placeholder="Enter your suggestions here"
        />
      </div>

      <div className="mb-4">
        <label className="block mb-2">
          Would you like to participate in future surveys or beta testing for
          the app?
        </label>
        {["Yes", "No"].map((option) => (
          <label key={option} className="inline-flex items-center mr-4">
            <input
              type="radio"
              name="participateFuture"
              value={option}
              checked={values.participateFuture === option}
              onChange={handleFieldChange}
              className="mr-2"
              required
            />
            {option}
          </label>
        ))}
      </div>

      <div className="flex justify-between">
        <button
          type="button"
          onClick={goBack}
          className="bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400"
        >
          Previous
        </button>
        <button
          type="submit"
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Submit Survey
        </button>
      </div>
    </form>
  );
};

export default Suggestions;
