import React from "react";

const SurveyHeader = () => {
  return (
    <div className="flex items-center mb-6 p-4 bg-white rounded-lg shadow-sm">
      <div className="flex items-center mr-4">
        <img src="/logo.png" alt="Company Logo" className="w-12 h-12" />
      </div>
      <div className="text-left">
        <h1 className="text-xl font-bold text-[#0475BB]">
          Welcome to Park Notify
        </h1>
        <p className="text-sm text-[#0475BB]">
          Fill the form below to get started
        </p>
      </div>
    </div>
  );
};

export default SurveyHeader;
