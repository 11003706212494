import SurveyHeader from "./SurveyHeader";
import { useNavigate } from "react-router-dom";

const TutorialScreen = () => {
  const navigate = useNavigate();

  const handleGoodToGo = () => {
    navigate("/");
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col p-6">
      <div className="max-w-3xl w-full mx-auto flex-grow flex flex-col">
        <SurveyHeader />

        <div className="flex-grow flex flex-col items-center justify-center mb-8">
          <img
            src="tutorial.png"
            alt="Tutorial"
            className="max-w-full h-auto rounded-lg shadow-md"
          />
          <p className="mt-4 text-center text-gray-600"></p>
        </div>

        <div className="flex justify-end">
          <button
            className="bg-[#0475BB] text-white px-6 py-2 rounded-lg hover:bg-[#035d94] transition-colors duration-300"
            onClick={handleGoodToGo}
          >
            Good to Go!
          </button>
        </div>
      </div>
    </div>
  );
};

export default TutorialScreen;
