import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserDemographic from "./UserDemographic";
import ParkingHabits from "./ParkingHabits";
import AppFeatures from "./AppFeatures";
import PromotionsOffers from "./PromotionsOffers";
import UserExperience from "./UserExperience";
import Suggestions from "./Suggestions";
import { DataContext } from "../dataContext/DataContext";

const MultiStepSurveyForm = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const { updateSurvey } = useContext(DataContext);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleChange = (newData) => {
    setFormData({ ...formData, ...newData });
  };

  const handleSubmit = () => {
    console.log(JSON.stringify(formData, null, 2));
    updateSurvey(formData);
    navigate("/tutorial");
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <UserDemographic
            nextStep={nextStep}
            handleChange={handleChange}
            values={formData}
          />
        );
      case 2:
        return (
          <ParkingHabits
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleChange}
            values={formData}
          />
        );
      case 3:
        return (
          <AppFeatures
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleChange}
            values={formData}
          />
        );
      case 4:
        return (
          <PromotionsOffers
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleChange}
            values={formData}
          />
        );
      case 5:
        return (
          <UserExperience
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleChange}
            values={formData}
          />
        );
      case 6:
        return (
          <Suggestions
            prevStep={prevStep}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            values={formData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-4xl p-8 bg-white rounded-lg shadow-md">
        {renderStep()}
      </div>
    </div>
  );
};

export default MultiStepSurveyForm;
